import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';



interface OrderCount {
  doctor: {
    name: string,
    id: number
  },
  clinic: {
    name: string,
    id: number,
    address: string
  },
  category: {
    id: number,
    name: string
  },
  schedule_session: {
    session_id: number,
    session_start_date: string,
    session_end_date: string,
    branch_name: string,
  },
  treatment: {
    id: number,
    name: string
  };
  appointment_from: string;
  patient: any,
  //fur temporary only
  clinic_name: string,
  doctor_name: string,
  current_state: number
}

@Injectable({
  providedIn: 'root'
})


export class StoreService {
  public FROM_DOCTOR = 'doctor'
  public FROM_CLINIC = 'clinic'

  private _orderCount = new BehaviorSubject<OrderCount>(null);

  private _orderCount$ = this._orderCount.asObservable();
  filteredItem;
  private _currentValue: any;
  getOrderCount(): Observable<OrderCount> {
    return this._orderCount$;
  }

  setOrderCount(latestValue) {
    this.getOrderCount().subscribe((res) => {

      this._currentValue = { ...res, ...latestValue }
    })
    return this._orderCount.next(this._currentValue);
  }
  remove() {
    this._orderCount.next(null);
  }
  getOrderItems() {
    return this._orderCount.getValue();
  }
  removeItem(listItem) {
    this.getOrderCount().subscribe((res) => {
      this.filteredItem = listItem?.map((v) => {
        return res[v];
      })
    })
    this._orderCount.next(this.filteredItem);
  }


  refreshAppointment(ref: string) {
    delete this.getOrderItems().category;
    delete this.getOrderItems().patient;
    delete this.getOrderItems().schedule_session;
    delete this.getOrderItems().treatment;
  }

  customRemove(lists: any) {
    for (let x of lists) {
      delete this.getOrderItems()[x];
    }
  }
  
  constructor() { }

}
