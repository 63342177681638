import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-shop",
  templateUrl: "./shop.component.html",
  styleUrls: ["./shop.component.scss"],
})
export class ShopComponent implements OnInit {
  public themeLogo: string = "assets/logo/logo.png"; // Change Logo
  constructor() {}

  ngOnInit(): void {}
}
