import { Component, ElementRef, HostListener, OnInit, ViewChild } from "@angular/core";
import { NavService, Menu } from "../../services/nav.service";
import { Router } from "@angular/router";
import { UserService } from "../../services/user.service";
import { RegisterComponent } from "../../../pages/account/register/register.component";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class MenuComponent implements OnInit {
  @ViewChild('menuRef', { static: true }) menuRef: ElementRef<any>;
  public menuItems: Menu[];

  loginStatus = false;
  memberInformation = {
    email: "",
    id: "",
  };
  constructor(
    private router: Router,
    public navServices: NavService,
    private userService: UserService,
    public route: Router
  ) {
    this.navServices.items.subscribe(
      (menuItems) => (this.menuItems = menuItems)
    );
    this.router.events.subscribe((event) => {
      this.navServices.mainMenuToggle = false;
    });
    this.userService.userIdChange.subscribe((change) => {
      console.log(change);
      if (change.length !== 0) {
        this.loginStatus = true;
        this.memberInformation.id = change;
      } else {
        this.loginStatus = false;
        this.memberInformation = {
          email: "",
          id: "",
        };
      }
    });
  }

  menuClass() {
    if (this.loginStatus == false) {
      return 'd-none nav-link'
    } else {
      return 'nav-link'
    }
  }

  ngOnInit(): void {
    let user: any = this.userService.userDetails;
    console.log(user);
    if (user.user_id != null && user.user_id.length > 0) {
      this.memberInformation.id = user.user_id;
      this.memberInformation.email = user.email;
      this.loginStatus = true;
    }
  }


  reloadCurrentRoute() {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([currentUrl]);
    });
  }


  registerOrLogout(action) {
    if (action == "register") {
      this.route.navigate(["/pages/login"]);
    } else if (action == "logout") {
      this.userService.logout();
    } else if (action == "dashboard") {
      this.router.navigate(["/pages/dashboard/accountinformation"]).then(() => {
        this.reloadCurrentRoute();
      });

    }
    else if (action == "appointment") {
      this.router.navigate(["/pages/dashboard/myappointment"]).then(() => {
        this.reloadCurrentRoute();
      });
    }
  }

  mainMenuToggle(): void {
    this.navServices.mainMenuToggle = !this.navServices.mainMenuToggle;
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    let el = this.menuRef.nativeElement;
    if (event.target !== el && !el.contains(event.target)) {
      this.navServices.mainMenuToggle = false;
    }
  }


  // Click Toggle menu (Mobile)
  toggletNavActive(item) {
    item.active = !item.active;
  }
}
