<!-- header start -->
<header class="sticky" [ngClass]="class">
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="main-menu border-section border-top-0">
          <!-- brand-logo  -->
          <div class="layout2-logo">
            <a [routerLink]="['/home']">
              <img [src]="themeLogo" class="img-fluid" alt="logo" />
              <!-- <img [src]="logoRTWA" height="150px" width="150px" class="img-fluid" alt="logo" /> -->
            </a>
          </div>
          <div>
            <form class="form_search" role="form">
              <input
                id="query search-autocomplete"
                type="search"
                placeholder="Find the best for your..."
                class="nav-search nav-search-field"
                aria-expanded="true"
              />
              <button type="button" name="nav-submit-button" class="btn-search">
                <i class="ti-search"></i>
              </button>
            </form>
          </div>
          <div class="menu-right pull-right">
            <app-settings></app-settings>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="main-nav-center">
          <app-menu></app-menu>
        </div>
      </div>
    </div>
  </div>
</header>
<!-- header end -->
