import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit {
  public list_user_rows = [];
  public imageUrl: string = 'assets/logo/logo.png'
  constructor() { }

  getRows = () => {
    let obj = [
      {
        name: 'test',
        age: '12'
      },
      {
        name: 'test 2',
        age: '19'
      },
    ];
    this.list_user_rows = obj
    console.log(this.imageUrl)
  }

  ngOnInit(): void {
    this.getRows();
  }


}
