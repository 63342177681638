import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpResponse, HttpErrorResponse, HttpRequest, HttpEvent } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from "rxjs/operators";
import { UserService } from "./user.service";

@Injectable()
export class AppHttpInterceptor implements HttpInterceptor {
    constructor(private userService: UserService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('isi request : ', request)
        const idToken = localStorage.getItem('token');

        return next.handle(request)
            .pipe(
                catchError((error: HttpErrorResponse) => {
                    console.log('isi error', error)
                    let errorMsg = '';
                    if (error.error instanceof ErrorEvent) {
                        console.log('This is client side error');
                        errorMsg = `Error: ${error.error.message}`;
                    } else {
                        if (error.status == 403) {
                            this.userService.logout();
                        }
                    }
                    console.log(errorMsg);
                    return throwError(error);
                })
            )
    }
}