import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoreService } from '../../services/store.service';

@Component({
  selector: 'app-step-wizard',
  templateUrl: './step-wizard.component.html',
  styleUrls: ['./step-wizard.component.scss']
})


export class StepWizardComponent implements OnInit {
  private isActive: string = '';
  public res: any;
  public routeData: any;
  checkGuard: boolean = true;
  private objStore = this.storeService.getOrderItems();
  private currentState: string;
  constructor(
    private storeService: StoreService,
    private router: Router,
    private activeRoute: ActivatedRoute
  ) {
    this.storeService.getOrderCount().subscribe((rest) => {
      this.res = rest
    })
    this.currentState = this.activeRoute.snapshot.data.step;
    this.activeRoute
      .data
      .subscribe(v => this.routeData = v?.step);

  }


  stepClick(step: string) {

    let url: any = this.stepCheck(step, true);
    switch (step) {
      case 'category':
        this.storeService.customRemove(['category', 'treatment', 'schedule_session', 'patient'])
        break;
    }
    switch (step) {
      case 'treatment':
        this.storeService.customRemove(['treatment', 'schedule_session', 'patient'])
        break;
    }
    switch (step) {
      case 'schedule_session':
        this.storeService.customRemove(['schedule_session'])
        break;
    }
    switch (step) {
      case 'summary':
        this.storeService.customRemove(['schedule_session'])
       
        break;
    }

    let params = this.activeRoute.snapshot.queryParams.ref
    if (url) {
      if (confirm("Are you sure want leave this appointment?, your data will be lost")) {
        this.router.navigate(['/pages/appointment/' + url], { queryParams: { ['ref']: params } })
      }
      return false;
    }

  }

  stepCheck(step: string, needUrl = false) {
    let urls: string;


    switch (step) {
      case 'category':
        if (this.res.hasOwnProperty('doctor') || this.res.hasOwnProperty('clinic')) {
          this.isActive = 'active'
          urls = 'category'
        } else {
          this.isActive = ''
        }
        break;
      case 'treatment':
        if (this.res.hasOwnProperty('category')) {
          this.isActive = 'active'
          urls = 'treatment'
        } else {
          this.isActive = ''
        }
        break;
      case 'schedule_session':
        if (this.res.hasOwnProperty('treatment') && this.res.hasOwnProperty('patient')) {
          this.isActive = 'active'
          urls = 'pick-schedule'
        } else {
          this.isActive = ''
        }
        break;
      case 'summary':
        if (this.res.hasOwnProperty('schedule_session')) {
          this.isActive = 'active'
          urls = 'summary'
        } else {
          this.isActive = ''
        }
        break;
    }
    if (this.routeData == step) {
      this.isActive = 'active current'
    }
    return !needUrl ? this.isActive : urls;
  }


  ngOnInit(): void {

  }
}
