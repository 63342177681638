<div class="container">
    <div class="row">
        <div class="col-md-12 mx-0">
            <ul id="progressbar">

                <li [ngClass]="stepCheck('category')" id="account"><strong class="label-step"><a
                            [ngClass]="stepCheck('category')" (click)="stepClick('category')" href="javascript:;">Choose
                            Category</a></strong>
                </li>

                <li [ngClass]="stepCheck('treatment')" id="personal"><strong class="label-step"><a
                            [ngClass]="stepCheck('treatment')" (click)="stepClick('treatment')"
                            href="javascript:;">Pick Treatment and Pet</a>
                    </strong>
                </li>


                <li [ngClass]="stepCheck('schedule_session')" id="payment">
                    <strong class="label-step"><a (click)="stepClick('schedule_session')"
                            [ngClass]="stepCheck('schedule_session')" href="javascript:;">Pick
                            Schedule</a></strong>
                </li>


                <li [ngClass]="stepCheck('summary')" id="confirm"><strong class="label-step">
                        <a [ngClass]="stepCheck('summary')" (click)="stepClick('summary')" href="javascript:;">Confirm
                            Appointment</a></strong></li>

            </ul>
        </div>
    </div>

</div>